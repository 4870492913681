import classNames from 'classnames';
import React from 'react';
import { getEnvUrls } from '../../../util/Util/EnvVar';
import Button from '../../Button';
import useTheme from 'hooks/UseTheme';

export enum AdvertiseSize {
  Small,
  Medium,
  Large,
}

interface Props {
  className?: string;
  inverseColor?: boolean;
  size?: AdvertiseSize;
}

export default function Advertise({ className, inverseColor, size = AdvertiseSize.Large }: Props): JSX.Element {
  const { url } = getEnvUrls();
  const { companyName } = useTheme();

  return (
    <div
      className={classNames('z-10 border p-6', className, {
        'text-white border-gray-300': !inverseColor,
        ' border-gray-200': inverseColor,
      })}
    >
      <span
        className={classNames('block', {
          'text-gray-600': inverseColor,
        })}
      >
        Try {companyName}?
      </span>
      <h4
        className={classNames('mb-6', {
          'font-bold': !inverseColor,
          'text-4xl': size === AdvertiseSize.Large,
          'text-2xl': size === AdvertiseSize.Medium,
          'text-lg': size === AdvertiseSize.Small,
        })}
      >
        Start working safely today
      </h4>
      <Button className='mb-2' onClick={() => window.open(`${url}/auth/signup`)}>
        Start your free trial
      </Button>
    </div>
  );
}
