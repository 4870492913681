import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { logger } from '../util/Logger';
import { ScreenSize } from 'types/ScreenSize';

interface Props {
  isOpen?: boolean;
  menuModeLargeBreakPoint?: ScreenSize;
  menuModeSmallBreakPoint?: ScreenSize;
  children: React.ReactNode;
}

export type MenuContextType = {
  isOpen: Props['isOpen'];
  setIsOpen: Dispatch<SetStateAction<Props['isOpen']>>;

  menuModeLargeBreakPoint: Props['menuModeLargeBreakPoint'];
  setMenuModeLargeBreakPoint: Dispatch<SetStateAction<Props['menuModeLargeBreakPoint']>>;

  menuModeSmallBreakPoint: Props['menuModeSmallBreakPoint'];
  setMenuModeSmallBreakPoint: Dispatch<SetStateAction<Props['menuModeSmallBreakPoint']>>;
};

const MenuContext = createContext<MenuContextType>({
  isOpen: undefined,
  setIsOpen: () => logger.warn('No Menu provider'),

  menuModeLargeBreakPoint: ScreenSize['2xl'],
  setMenuModeLargeBreakPoint: () => logger.warn('No Menu provider'),

  menuModeSmallBreakPoint: ScreenSize['md'],
  setMenuModeSmallBreakPoint: () => logger.warn('No Menu provider'),
});

export function useMenuContext(): MenuContextType {
  return useContext(MenuContext);
}

export function MenuProvider({ children }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<Props['isOpen']>(false);
  const [menuModeLargeBreakPoint, setMenuModeLargeBreakPoint] = useState<Props['menuModeLargeBreakPoint']>();
  const [menuModeSmallBreakPoint, setMenuModeSmallBreakPoint] = useState<Props['menuModeSmallBreakPoint']>();

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
        menuModeLargeBreakPoint,
        setMenuModeLargeBreakPoint,
        menuModeSmallBreakPoint,
        setMenuModeSmallBreakPoint,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
