import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useRealm from '../../../hooks/realm/UseRealm';
import useMenu, { MenuMode } from '../../../hooks/UseMenu';
import { IconContext } from '@phosphor-icons/react';
import useTheme from 'hooks/UseTheme';

export interface Props {
  path?: string;
  icon?: ReactNode;
  padding?: number;
  onClick?: () => void;
  disabled?: boolean;
  grayedOut?: boolean;
  className?: string;
  name: string;
  children: ReactNode;
}

export default function MenuItem({ path, icon, grayedOut, disabled, onClick, children, className, name }: Props): JSX.Element {
  const { setIsOpen, isOpen, menuMode } = useMenu();
  const { realm, realms, setRealm } = useRealm();
  const { isAgencyTheme } = useTheme();
  const location = useLocation();
  const isActive = !path ? false : path.startsWith(location.pathname) || location.pathname.startsWith(path);

  const defaultWrapClasses = classNames('flex items-center text-zinc-300 hover:text-zinc-100 py-3', {
    // center the menu when its not open so the item is in the middle
    'justify-center': !isOpen,
    'pl-5': isOpen,
    'mx-3': isAgencyTheme,
  });

  /**
   * callback when we click on a link
   */
  const onLinkClick = useCallback(() => {
    // we close the menu only when we are in the small mode
    if (menuMode === MenuMode.SMALL) {
      setIsOpen(false);
    }

    // if the realm has not been set, because the user comes from a page without realm context (e.g. /account)
    // we set the first realm as active
    if (!realm) {
      setRealm(realms[0]);
    }
  }, [realm, realms, menuMode]); //eslint-disable-line

  /**
   * Render the inner item with or without an icon
   */
  const innerItem = (
    <>
      <IconContext.Provider
        value={{
          size: isAgencyTheme ? 23 : 20,
          className: classNames('shrink-0', {
            'text-zinc-50': isActive,
          }),
        }}
      >
        {icon}
      </IconContext.Provider>
      {isOpen && <div className={classNames('mx-3 leading-6 w-full', className)}>{children}</div>}
      {!isOpen && (
        <div className='hidden group-hover:block absolute z-[99999] px-4 py-2 top-1 left-[3.6rem] rounded-md whitespace-nowrap drop-shadow-lg text-sm bg-gray-900/80 text-white'>
          <span>{name}</span>
        </div>
      )}
    </>
  );

  /**
   * When we pass an PATH variable, we should render the item with a <link> component
   * Otherwise, we use a <span> with an onClick event attached to control it.
   */
  let item: React.ReactNode;
  if (path) {
    item = disabled ? (
      <span className={classNames(defaultWrapClasses, 'opacity-60 cursor-not-allowed')}>{innerItem}</span>
    ) : (
      <NavLink
        onClick={onLinkClick}
        to={path}
        className={navData =>
          classNames(defaultWrapClasses, {
            'bg-zinc-600 text-zinc-50': navData.isActive && !isAgencyTheme,
            'bg-gray-600 text-gray-50 rounded-xl': navData.isActive && isAgencyTheme,
            'opacity-60': grayedOut || disabled,
          })
        }
      >
        {innerItem}
      </NavLink>
    );
  } else {
    item = (
      <p className={classNames('cursor-pointer', defaultWrapClasses)} onClick={onClick}>
        {innerItem}
      </p>
    );
  }

  return <li className='group relative'>{item}</li>;
}
