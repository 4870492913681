import { Theme, useThemeContext } from 'context/ThemeContext';
import { useEffect } from 'react';

interface ReturnType {
  theme: Theme;
  isStorroTheme: boolean;
  isAgencyTheme: boolean;
  companyName: string;
}

/**
 * Small custom hook that detect what kind of theme we are running
 */
function useTheme(): ReturnType {
  const { theme, setTheme } = useThemeContext();

  /**
   * Based on the url we can determine if we should set the theme for Agency Drive
   */
  useEffect(() => {
    if (window.location.hostname.includes('agencydrive')) {
      setTheme(Theme.AgencyDrive);
    }
  }, [setTheme]);

  return {
    theme,
    isStorroTheme: theme === Theme.Storro,
    isAgencyTheme: theme === Theme.AgencyDrive,
    companyName: theme === Theme.Storro ? 'Storro' : 'Agency Drive',
  };
}

export default useTheme;
