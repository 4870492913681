import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import CreateAccount from 'assets/lock.svg';
import StorroLogoBg from 'assets/logoWithOpacity.svg';
import AgencyDriveGlobeBg from 'assets/agency-drive-globe.svg';
import { useSplitScreenContext } from 'context/SplitScreenContext';
import ListItem from './ListItem';
import { IS_IOS_APP } from 'const';
import StorroLogoLight from 'assets/storro-logo-and-text-light.svg';
import logoStorroDark from 'assets/logo-dark.svg';
import useTheme from 'hooks/UseTheme';
import AgencyDriveLogo from 'assets/agency-drive-logo.svg';

interface Props {
  children: React.ReactNode;
}

const DEFAULT_LIST_ITEMS = [
  'Prevent reputation damage',
  'Increase productivity',
  'Save IT costs',
  'Prevent capital loss',
  'Increase job satisfaction',
];

const DEFAULT_TITLE = 'Strengthen your corporate image and increase your productivity';

export default function SplitScreenWrapper({ children }: Props): JSX.Element {
  const [image, setImage] = useState<string>('');

  const { showSidebar, sidebarItems, sidebarTitle, image: imagePath, maxWidthClassName } = useSplitScreenContext();
  const { isAgencyTheme, isStorroTheme, companyName } = useTheme();

  /**
   * Define the title of the page by checking the context.Title
   */
  const title = useMemo(() => {
    return sidebarTitle ?? DEFAULT_TITLE;
  }, [sidebarTitle]);

  /**
   * Define the items of the page by checking the context.Items
   */
  const items = useMemo(() => {
    return sidebarItems ?? DEFAULT_LIST_ITEMS;
  }, [sidebarItems]);

  /**
   * Define the title of the page by checking the context.Title
   */
  useEffect(() => {
    if (!imagePath) {
      setImage(CreateAccount);
    } else {
      setImage(imagePath);
    }
  }, [imagePath]);

  return (
    <div
      className={classNames('w-full h-full flex  sm:p-10 overflow-auto', {
        'bg-agency-background': isAgencyTheme,
        'bg-[#263641]': isStorroTheme,
      })}
    >
      <div
        style={{
          backgroundImage: `url("${isStorroTheme ? StorroLogoBg : AgencyDriveGlobeBg}")`,
          backgroundSize: '175%',
          backgroundPosition: '-10%',
          backgroundRepeat: 'no-repeat',
        }}
        className={classNames('z-0 absolute right-0 bottom-0 top-0 left-0', {
          'opacity-35': isAgencyTheme,
        })}
      />
      <div
        className={classNames('z-10 w-full flex flex-col items-center', {
          'pt-12': isAgencyTheme, // we show the logo of the agency-drive above, add some more padding to the top so the logo is visibile also on smaller screens
        })}
      >
        <main
          className={classNames(
            'grow relative',
            'sm:grow-0 sm:h-auto sm:flex w-full sm:w-3/4 lg:3/4 2xl:w-full sm:shadow-xl sm:border sm:mt-auto', // styles for non mobile devices
            {
              'max-w-3xl': showSidebar && !maxWidthClassName,
              'max-w-xl': !showSidebar && !maxWidthClassName,
              'sm:rounded': isStorroTheme,
              'sm:rounded-xl': isAgencyTheme,
            },
            maxWidthClassName ?? '',
          )}
        >
          {isAgencyTheme && (
            <div className='absolute w-full flex justify-center -top-16'>
              <img src={AgencyDriveLogo} alt='logo' width='120' />
            </div>
          )}

          <div
            className={classNames('h-full bg-white w-full', {
              'lg:w-6/12': showSidebar,
              'sm:rounded': isStorroTheme,
              'sm:rounded-xl': isAgencyTheme,
            })}
          >
            <div
              className={classNames('h-full flex flex-col pt-8 pb-2 px-6 sm:py-8 sm:px-9', {
                'pt-[calc(30px+env(safe-area-inset-top))] pb-safe': IS_IOS_APP, // For IOS we have to deal with the notch by using the safe-area
              })}
            >
              <div className='sm:hidden flex justify-center mb-10'>
                {isStorroTheme && <img src={logoStorroDark} alt='logo' width={100} />}
                {isAgencyTheme && <img src={AgencyDriveLogo} alt='logo' width={125} />}
              </div>

              {children}
            </div>
          </div>
          {showSidebar && (
            <div
              className={classNames('hidden lg:flex flex-col py-8 px-10 w-6/12', {
                'bg-agency-background rounded-r-xl': isAgencyTheme,
                'bg-gray-200': isStorroTheme,
              })}
            >
              <div className='flex justify-center mb-3'>
                <img src={image} alt='create account' className='w-[60%]' />
              </div>
              <h2 className='text-xl w-full mb-10 text-center'>{title}</h2>
              <div className='mb-10'>
                {items.map(item => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </div>
            </div>
          )}
        </main>
        <div className='hidden sm:flex items-center gap-x-2 mt-5 mb-auto pb-4'>
          {isStorroTheme && <img src={StorroLogoLight} alt='logo' width='100' />}
          <span className='text-gray-500 text-sm'>
            Copyright © {new Date().getFullYear()} {companyName} B.V.
          </span>
        </div>
      </div>
    </div>
  );
}
