import React, { createContext, useContext, useState } from 'react';
import { logger } from '../util/Logger';

export enum Theme {
  Storro,
  AgencyDrive,
}

interface Props {
  theme: Theme;
  children: React.ReactNode;
}

type ThemeContextType = {
  theme: Props['theme'];
  setTheme: (theme: Props['theme']) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  theme: Theme.Storro,
  setTheme: () => logger.warn('no Theme provider'),
});

export function useThemeContext(): ThemeContextType {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }: Props): JSX.Element {
  const [theme, setTheme] = useState<Props['theme']>(Theme.Storro);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}
