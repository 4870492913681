import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import hexRgb from 'hex-rgb';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MobileComponentSwitcher from './components/Admin/MobileComponentSwitcher';
import DesktopClientNotification from './components/DesktopClient/DesktopClientNotification';
import Loading from './components/Loading';
import RouteComponentWithTitle from './components/Route/RouteComponentWithTitle';
import RouteClientPortal from './components/Wrappers/Route/RouteClientPortal';
import RouteSplitScreenWrapper from './components/Wrappers/Route/RouteSplitScreenWrapper';
import RouteWrapper from './components/Wrappers/Route/RouteWrapper';
import { IS_MOBILE_APP, NON_SECURE_PATHS, STANDALONE_PAGES } from './const';
import { useSkipPlan } from './context/SkipSelectPlanContext';
import { useStorroApi } from './context/StorroApiContext';
import useDetectVersionUpdate from './hooks/UseDetectVersionUpdate';
import useLoadRealm from './hooks/UseLoadRealm';
import useUser from './hooks/useUser';
import { QuickShareType } from './types';
import { logger } from './util/Logger';
import { SubscriptionStatus } from 'types/Payment';
import useMenu from 'hooks/UseMenu';
import { ScreenSize } from 'types/ScreenSize';

const DesktopClientDownloads = React.lazy(() => import('./components/DesktopClient/DesktopClientDownloads'));
const LoginForm = React.lazy(() => import('./components/Auth/LoginForm'));
const RegisterForm = React.lazy(() => import('./components/Auth/RegisterForm'));
const ReplyDemoteAdmin = React.lazy(() => import('./components/Auth/ReplyDemoteAdmin'));
const SignupForm = React.lazy(() => import('./components/Auth/SignupForm'));
const PageNotFound = React.lazy(() => import('./components/PageError/PageNotFound'));
const AdminBilling = React.lazy(() => import('./pages/admin/Billing'));
const Statistics = React.lazy(() => import('./pages/admin/Stats'));
const Overview = React.lazy(() => import('./pages/Overview'));
const NoRealmReRouteToAdminIfPossible = React.lazy(() => import('./pages/admin/NoRealmReRouteToAdminIfPossible'));
const Policies = React.lazy(() => import('./pages/admin/Policies'));
const Settings = React.lazy(() => import('./pages/admin/Settings'));
const BrandingPreview = React.lazy(() => import('./pages/admin/Settings/BrandingPreview'));
const SuperAdminConsole = React.lazy(() => import('./pages/admin/SuperAdminConsole'));
const Users = React.lazy(() => import('./pages/admin/Users'));
const CreateRealm = React.lazy(() => import('./pages/CreateRealm'));
const Files = React.lazy(() => import('./pages/Files'));
const NoRealms = React.lazy(() => import('./pages/NoRealms'));
const Account = React.lazy(() => import('./pages/Account'));
const AccountManage = React.lazy(() => import('./pages/Account/AccountManage'));
const AccountDevices = React.lazy(() => import('./pages/Account/Devices'));
const AccountNotifications = React.lazy(() => import('./pages/Account/Notifications'));
const AccountSecurity = React.lazy(() => import('./pages/Account/Security'));
const VerifyEmailAddress = React.lazy(() => import('./pages/Account/VerifyEmailAddress'));
const QuickShareClient = React.lazy(() => import('./pages/QuickShare/Client'));
const QuickShareItem = React.lazy(() => import('./pages/QuickShare/Item'));
const QuickShareList = React.lazy(() => import('./pages/QuickShare/List'));
// const Recent = React.lazy(() => import('./pages/Recent'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const SelectPlan = React.lazy(() => import('./pages/SelectPlan'));
const SelectPayment = React.lazy(() => import('./pages/SelectPayment'));

function App(): JSX.Element {
  const { storroApi } = useStorroApi();
  const { user, setUser, setSuperAdminMode } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { setMenuModeLargeBreakPoint } = useMenu();
  // const { setLoading } = usePageLoader();
  const { skipPlan, setSkipPlan } = useSkipPlan();

  /**
   * Load the realms and set the route correctly e.g. / --> /realm/:realmPubKey
   */
  const { realm, realms, realmCount, reload } = useLoadRealm();

  /**
   * Flag for routes that are not secured
   */
  const nonSecureRoute = NON_SECURE_PATHS.includes(location.pathname);

  /**
   * Flag that indicate if a user is superadmin and the superadmin mode is enabled
   */
  const superAdminModeEnabled = storroApi.superAdminMode;

  /**
   * Detect version mismatch and show a modal to refresh the page
   */
  useDetectVersionUpdate();

  /**
   * Check if we have reconnected to the api. If so, if so, reload the realms
   * (this will reload all dependencies in that context) and reconnect to the
   * websocket (we cannot rely on the reconnect algorithm as this can be delayed).
   * We do this because we don't known if we've gotten any events from the
   * server. Just reload everything to make sure we have the latest data.
   */
  useEffect(() => {
    const websocketReconnected = () => {
      logger.debug('Reloading the Realm after websocket reconnect.');
      reload(undefined, true);
    };
    storroApi.webSocketEvents.addListener('websocketReconnected', websocketReconnected);
    return () => {
      storroApi.webSocketEvents.removeListener('websocketReconnected', websocketReconnected);
    };
  }, [reload, storroApi]);

  /**
   * Navigate to the /login page when there is no user
   * This also prevent an user that is not logged in, to visit the protected routes
   *
   * TODO we should fetch the user account when refreshing the page and show the page loader while fetching
   * TODO when the user cannot be fetched, then we should redirect to the login page
   */
  useEffect(() => {
    // ignore if the user hits a non_secure_path
    if (nonSecureRoute) return;

    // If the user is loggedIn, checked by the API via the session_storage
    // Set the user and/or the superAdmin mode
    if (storroApi.isLoggedIn) {
      setUser(storroApi.account);
      setSuperAdminMode(storroApi.superAdminMode);

      return;
    }

    // redirect if we hit another path
    sessionStorage.setItem('redirectAfterLogin', location.pathname);
    navigate('/auth/login');
  }, [navigate, user, setUser, storroApi, setSuperAdminMode, nonSecureRoute, location.pathname]);

  /**
   * Set the skipPlan flag if the user has skipped the plan selection for the browser session
   */
  useEffect(() => {
    const skipPlan = sessionStorage.getItem('skipPlan');
    if (skipPlan) {
      setSkipPlan(true);
    }
  }, [setSkipPlan]);

  /**
   * Parse the color from hex to RGB and set the css primary color variable
   */
  useEffect(() => {
    if (realm?.color) {
      const hex = hexRgb(realm.color);
      document.documentElement.style.setProperty('--color-primary', `${hex.red}, ${hex.green}, ${hex.blue}`);
    } else {
      // fallback on the default Storro color
      document.documentElement.style.setProperty('--color-primary', ' 37, 99, 235');
    }
  }, [realm]);

  /**
   * set the style for an IOS device
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    StatusBar.setStyle({ style: Style.Light }).catch(() => {});
  }, []);

  /**
   * Listen to APP events appUrlOpen and backButton
   *
   * - appUrlOpen: when the user click on an storro app link e.g. a link in an email
   * - backButton: this is the hardware button event for the backbutton in Android
   *
   * If so, get the part after the host, and navigate to that route instead
   */
  useEffect(() => {
    // listen to urls that match the scheme from e.g. a link in an email
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const parsedUrl = new URL(event.url);
      const slug = event.url.replace(parsedUrl.origin, '');
      navigate(slug);
    });

    // Listen to the hardware button event for Android
    // If we can't go back anymore, i.e. if we are on the homepage and the user click on the backbutton
    // we should close the app
    CapacitorApp.addListener('backButton', canGoBack => {
      if (location.pathname === `/realm/${realm?.publicKey}/projects`) {
        CapacitorApp.exitApp();
      } else if (canGoBack) {
        navigate(-1);
      } else {
        CapacitorApp.exitApp();
      }
    });

    // Clear all events when the App is rerendered
    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [location, realm, navigate]);

  /**
   * Based on the path, where we detect if we are in the admin section
   * we changed the breakpoints for our menu
   */
  useEffect(() => {
    if (location.pathname.includes('/admin')) {
      setMenuModeLargeBreakPoint(ScreenSize.xl);
    } else {
      setMenuModeLargeBreakPoint(ScreenSize['2xl']);
    }
  }, [location.pathname, setMenuModeLargeBreakPoint]);

  /**
   * Set a mobile class on the <body /> in case we are in the mobile app
   */
  useEffect(() => {
    if (IS_MOBILE_APP) {
      document.body.classList.add('mobile');
    }
  }, []);

  /**
   * Remove the splashscreen for the mobile APP, as we can show the Storro loader instead
   */
  useEffect(() => {
    if (IS_MOBILE_APP) {
      SplashScreen.hide();
    }
  }, []);

  if (nonSecureRoute) {
    return (
      <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
        <Routes>
          <Route path='/qs' element={<QuickShareClient />} />
          <Route element={<RouteSplitScreenWrapper showSidebar={true} />}>
            <Route
              path='/binaries'
              element={
                <RouteComponentWithTitle title='Download Binaries'>
                  <DesktopClientDownloads />
                </RouteComponentWithTitle>
              }
            />
          </Route>
          <Route path='auth' element={<RouteSplitScreenWrapper />}>
            <Route
              path='signup'
              element={
                <RouteComponentWithTitle title='Signup'>
                  <SignupForm />
                </RouteComponentWithTitle>
              }
            />
            <Route
              path='login'
              element={
                <RouteComponentWithTitle title='Login'>
                  <LoginForm />
                </RouteComponentWithTitle>
              }
            />
            <Route
              path='register'
              element={
                <RouteComponentWithTitle title='Register'>
                  <RegisterForm />
                </RouteComponentWithTitle>
              }
            />
            <Route
              path='email'
              element={
                <RouteComponentWithTitle title='Login Verify Email'>
                  <VerifyEmailAddress />
                </RouteComponentWithTitle>
              }
            />
            <Route
              path='demote'
              element={
                <RouteComponentWithTitle title='Demote'>
                  <ReplyDemoteAdmin />
                </RouteComponentWithTitle>
              }
            />
            <Route
              path='*'
              element={
                <RouteComponentWithTitle title='Page not Found'>
                  <PageNotFound />
                </RouteComponentWithTitle>
              }
            />
          </Route>

          {/*Any other route we redirect to the login page*/}
          <Route path='*' element={<Navigate to='/auth/login' />} />
        </Routes>
      </Suspense>
    );
  }

  /**
   * If the user has request a password reset via the recovery key
   * present a page where he can change his password
   */
  if (storroApi.requirePasswordChange) {
    return (
      <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
        <Routes>
          <Route element={<RouteSplitScreenWrapper showSidebar={false} />}>
            <Route
              path='*'
              element={
                <RouteComponentWithTitle title='Reset Password'>
                  <ResetPassword />
                </RouteComponentWithTitle>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  /**
   * In case the user does not have any realms assigned
   */
  if (realmCount === 0) {
    return (
      <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
        <Routes>
          <Route element={<RouteWrapper />}>
            <Route
              path='/overview'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <Overview />
                </Suspense>
              }
            />
          </Route>
          <Route element={<RouteSplitScreenWrapper />}>
            <Route
              path='/realm/create'
              element={
                <RouteComponentWithTitle title='Create Realm'>
                  <CreateRealm />
                </RouteComponentWithTitle>
              }
            />
          </Route>
          <Route element={<RouteWrapper />}>
            <Route path='account'>
              <Route
                path=''
                element={
                  <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Account'>
                      <Account />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
              <Route
                path='manage'
                element={
                  <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Manage'>
                      <AccountManage standalonePage={true} />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
              <Route
                path='devices'
                element={
                  <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Devices'>
                      <AccountDevices standalonePage={true} />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
              <Route
                path='notifications'
                element={
                  <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Notifications'>
                      <AccountNotifications standalonePage={true} />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
              <Route
                path='security'
                element={
                  <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Security'>
                      <AccountSecurity standalonePage={true} />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='*'
              element={
                <RouteComponentWithTitle>
                  <NoRealms />
                </RouteComponentWithTitle>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  /**
   * Show the select payment page if the realm requires payment details
   */
  if (realm && realm.needPayment && !realm?.canSkipPayment && !superAdminModeEnabled) {
    return (
      <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
        <Routes>
          <Route element={<RouteSplitScreenWrapper showSidebar={false} />}>
            <Route
              path='*'
              element={
                <RouteComponentWithTitle title='Payment'>
                  <SelectPayment />
                </RouteComponentWithTitle>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  // We show the select plan page if its marked and the user has not skipped the plan selecten
  if (realm && realm.status === SubscriptionStatus.TrialExpired && !skipPlan && !superAdminModeEnabled) {
    return (
      <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
        <Routes>
          <Route element={<RouteSplitScreenWrapper showSidebar={false} />}>
            <Route
              path='*'
              element={
                <RouteComponentWithTitle title='Select plan'>
                  <SelectPlan />
                </RouteComponentWithTitle>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  if (realms.length > 0) {
    return (
      <>
        <Routes>
          <Route element={<RouteWrapper />}>
            <Route
              path='/overview'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <Overview />
                </Suspense>
              }
            />
          </Route>
          {/* Currently for testing we enable a new route */}
          {/* TODO we can remove this one when the quickshare is in place as that will act as main entry point */}
          {realm?.isAdmin && (
            <Route element={<RouteClientPortal />}>
              <Route
                path='/realm/:realmPubKey/client-portal-preview'
                element={
                  <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                    <RouteComponentWithTitle title='Client Portal Preview'>
                      <BrandingPreview />
                    </RouteComponentWithTitle>
                  </Suspense>
                }
              />
            </Route>
          )}

          <Route
            path='/qs'
            element={
              <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                <QuickShareClient />
              </Suspense>
            }
          />
          <Route element={<RouteSplitScreenWrapper showSidebar={true} />}>
            <Route
              path='/binaries'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Download Binaries'>
                    <DesktopClientDownloads />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
          </Route>

          <Route element={<RouteSplitScreenWrapper showSidebar={false} />}>
            <Route
              path='/realm/create'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Create Realm'>
                    <CreateRealm />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
          </Route>

          {/*This route will redirect the user to the first realm. In case the user is not an admin, he will be redirected to the realm user page*/}
          {/* This clean url /admin/user and /admin/stats is used for our email communication */}
          <Route path='/admin'>
            <Route
              path='stats'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Admin Statistics'>
                    <NoRealmReRouteToAdminIfPossible />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
            <Route
              path='users'
              element={
                <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <NoRealmReRouteToAdminIfPossible />
                </Suspense>
              }
            />
          </Route>

          <Route path='/account' element={<Navigate to='/account/personal' />} />
          <Route path='/account/:slug' element={<RouteWrapper />}>
            <Route
              path=''
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Account'>
                    <Account />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
          </Route>

          <Route path='/realm/:realmPubKey' element={<RouteWrapper />}>
            <Route path='' element={<Navigate to={`/realm/${realm?.publicKey}/projects`} />} />
            <Route
              path='projects'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Spaces'>
                    <Files />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
            <Route
              path='projects/:projectId/files'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Space Files'>
                    <Files />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
            {/* <Route
              path='recent'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='Recent'>
                    <Recent />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            /> */}
            <Route
              path='quickshare'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='QuickShare'>
                    <QuickShareList quickShareType={QuickShareType.Share} />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
            <Route
              path='quickshare/:quickShareId'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <QuickShareItem />
                </Suspense>
              }
            />
            <Route
              path='request'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <RouteComponentWithTitle title='File Request'>
                    <QuickShareList quickShareType={QuickShareType.Request} />
                  </RouteComponentWithTitle>
                </Suspense>
              }
            />
            <Route
              path='request/:quickShareId'
              element={
                <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                  <QuickShareItem />
                </Suspense>
              }
            />
            {realm?.isAdmin && (
              <>
                <Route
                  path='admin'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <MobileComponentSwitcher
                        mobileComponent={<Overview asAdmin={true} />}
                        webComponent={
                          <RouteComponentWithTitle title='Admin Statistics'>
                            <Statistics />
                          </RouteComponentWithTitle>
                        }
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='admin/stats'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <RouteComponentWithTitle title='Admin Statistics'>
                        <Statistics />
                      </RouteComponentWithTitle>
                    </Suspense>
                  }
                />
                <Route
                  path='admin/users'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <RouteComponentWithTitle title='Admin Users'>
                        <Users />
                      </RouteComponentWithTitle>
                    </Suspense>
                  }
                />
                <Route
                  path='admin/billing'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <RouteComponentWithTitle title='Admin Billing'>
                        <AdminBilling />
                      </RouteComponentWithTitle>
                    </Suspense>
                  }
                />
                <Route
                  path='admin/settings'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <RouteComponentWithTitle title='Admin Settings'>
                        <Settings />
                      </RouteComponentWithTitle>
                    </Suspense>
                  }
                />
                <Route
                  path='admin/policies'
                  element={
                    <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                      <RouteComponentWithTitle title='Admin Policies'>
                        <Policies />
                      </RouteComponentWithTitle>
                    </Suspense>
                  }
                />
                {user?.isSuperAdmin && (
                  <>
                    <Route
                      path='admin/console'
                      element={
                        <Suspense fallback={<Loading isFixed={false} initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                          <RouteComponentWithTitle title='Admin Console'>
                            <SuperAdminConsole />
                          </RouteComponentWithTitle>
                        </Suspense>
                      }
                    />
                  </>
                )}
              </>
            )}
          </Route>
          <Route
            path='*'
            element={
              <Suspense fallback={<Loading initialWithoutFade={true} visible={true} loadingText='...loading' />}>
                <RouteComponentWithTitle title='Page Not Found'>
                  <PageNotFound />
                </RouteComponentWithTitle>
              </Suspense>
            }
          />
        </Routes>
        {/* Show the desktopClient notification only for NON-standalone pages */}
        {!STANDALONE_PAGES.includes(location.pathname) && <DesktopClientNotification />}
      </>
    );
  }

  return <></>;
}

export default App;
