import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useBannerContext } from 'context/BannerContext';
import useListRealmsModal from 'hooks/realm/UseListRealmsModal';
import useRealm from 'hooks/realm/UseRealm';
import useMenu, { MenuMode } from 'hooks/UseMenu';
import useUser from 'hooks/useUser';
import CrossFadeImage from '../CrossFadeImage';
import RealmSwitcher from '../Realm/RealmSwitcher';
import Menu from './Menu';
import RealmStatusBadge from './RealmStatusBadge';
import { IconContext, List, PencilSimple, TextOutdent } from '@phosphor-icons/react';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import { useNavigate } from 'react-router-dom';
import RealmSwitcherButton from 'components/Realm/RealmSwitcherButton';
import maxLength from 'util/MaxLength';
import BrandRealmModal from 'components/Realm/Settings/UpdateRealmBranding/BrandRealmModal';
import useModal from 'hooks/UseModal';
import useTheme from 'hooks/UseTheme';

interface Props {
  mainRef: React.RefObject<HTMLElement | null>;
}

function SidebarLeft({ mainRef }: Props): JSX.Element {
  const [logoOrientation, setLogoOrientation] = useState<'landscape' | 'portrait'>('portrait');

  const { isOpen, setIsOpen, menuMode, menuWidth } = useMenu();
  const { superAdminMode } = useUser();
  const { realm, realms, realmLogo, realmCustomLogo, loadRealm } = useRealm();
  const { bannerIsVisible } = useBannerContext();
  const isMobileDevice = useIsMobileDevice();
  const navigate = useNavigate();
  const { showModal: showModalBranding, closeModal: closeModalBranding, modalIsVisible: modalIsVisibleBranding } = useModal();
  const { isAgencyTheme, isStorroTheme } = useTheme();

  // Init the dialog for manage the user realms
  const { openModal: openManageRealmModal, dialog: manageRealmDialog } = useListRealmsModal();

  /**
   * Toggle the sidebar menu but only if the menu is in small mode
   */
  const toggleMenu = () => {
    setIsOpen(prevState => {
      const newState = !prevState;
      if (menuMode === MenuMode.LARGE) {
        localStorage.setItem('leftMenuState', newState ? 'open' : 'closed');
      }

      return newState;
    });
  };

  /**
   * When clicked outside, on the mainRef element: Close the sidebar when opened
   */
  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (menuMode === MenuMode.SMALL && isOpen && mainRef.current && event.target && mainRef.current.contains(event.target as Node)) {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        toggleMenu();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [mainRef, isOpen]); //eslint-disable-line

  /**
   * Detect the Logo orientation
   */
  useEffect(() => {
    if (!realmLogo) return;

    const img = new Image();
    img.src = realmLogo;
    img.onload = () => setLogoOrientation(img.width > img.height ? 'landscape' : 'portrait');
  }, [realmLogo]);

  return (
    <>
      <aside
        style={{
          width: menuWidth,
        }}
        className={classNames('fixed bottom-0 top-0 transition-[width] z-[60]', {
          'bg-zinc-700': !isAgencyTheme,
          'bg-gray-700': isAgencyTheme,
          // We don't want to show the sidebar on mobile devices
          hidden: isMobileDevice,
          'mt-8': bannerIsVisible,
        })}
      >
        <button
          className={classNames('absolute block z-50 top-[6px]', {
            'mt-3': bannerIsVisible,
            'left-[50%] -translate-x-[50%]': !isOpen,
            'left-4': isOpen,
          })}
          onClick={toggleMenu}
        >
          <IconContext.Provider
            value={{
              size: 20,
              className: 'animate-fast text-zinc-100',
            }}
          >
            {!isOpen && <List />}
            {isOpen && <TextOutdent />}
          </IconContext.Provider>
        </button>

        <div className='w-full h-full flex flex-col pt-3'>
          <>
            {isOpen ? (
              <>
                <div
                  onClick={() => navigate(`/realm/${realm?.publicKey}`)}
                  className={classNames('relative mx-auto px-2 cursor-pointer', {
                    'w-1/2': logoOrientation === 'portrait',
                    'w-3/4': logoOrientation === 'landscape',
                  })}
                >
                  {realmLogo && <CrossFadeImage src={realmLogo} alt='logo' />}

                  {realmCustomLogo === undefined && realm?.isAdmin && (
                    <>
                      <div className='absolute right-1 bottom-1 flex items-center justify-center text-white bg-primary rounded-full p-1 border border-zinc-700'>
                        <button
                          onClick={e => {
                            e.stopPropagation();
                            showModalBranding();
                          }}
                        >
                          <PencilSimple size={14} weight='light' />
                        </button>
                      </div>
                      <BrandRealmModal
                        isVisble={modalIsVisibleBranding}
                        onRequesCloseModal={closeModalBranding}
                        onSuccess={async realm => {
                          // reload the realm to reflect the changes
                          await loadRealm(realm.id);
                        }}
                      />
                    </>
                  )}
                </div>
                <RealmStatusBadge className='mt-3' />
              </>
            ) : (
              <div
                className={classNames('flex justify-center mt-3 mb-2 cursor-pointer', {
                  'mt-9': !isOpen,
                })}
                onClick={() => navigate(`/realm/${realm?.publicKey}`)}
              >
                <img
                  className={classNames('rounded', {
                    'w-7': isStorroTheme,
                    'w-10': isAgencyTheme,
                  })}
                  src={realmLogo}
                  alt='logo'
                />
              </div>
            )}
          </>

          {/* We only show the realm switcher when the menu is fully open */}
          {isOpen && (
            <div>
              {realms.length > 0 && !superAdminMode && (
                <>
                  {realms.length === 1 && realm && (
                    <p className='font-semibold px-3 py-3 text-zinc-50 text-center'>{maxLength(realm.name, 20)}</p>
                  )}
                  {/* When there are more than 1 realms attached to the user */}
                  {/* or in case there is no realm selected, show the realmSwitcher button as well */}
                  {(realms.length > 1 || (realms.length === 0 && !realm)) && (
                    <RealmSwitcher onRealmSwitch={toggleMenu} includeCreateRealm={true}>
                      <RealmSwitcherButton realm={realm} showDropdownIcon={realms.length > 1} />
                    </RealmSwitcher>
                  )}
                </>
              )}
              {realms.length > 0 && superAdminMode && (
                <RealmSwitcherButton realm={realm} showDropdownIcon={realms.length > 1} onClick={openManageRealmModal} />
              )}
            </div>
          )}

          <Menu />
        </div>

        {manageRealmDialog}
      </aside>
    </>
  );
}

export default memo(SidebarLeft);
